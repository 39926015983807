const init = () => {
  $('.invest-today').on('click', handleAccountCreationClick);
};

export default {
  type: 'selector',
  selector: '.investor-entity-accounts-show',
  init,
};

const handleAccountCreationClick = () => {
  window.parent.postMessage({message: 'radix/account/create'}, '*');
}
