import { fetchFields } from '../select_filters/form_filters';
import { initFieldFeatures } from '../utils/fields';

/**
 * Handle changes to the field that determines the type of legal entity been
 * created by fetching and placing the fields required for the chosen type.
 */
const handleLegalEntityTypeChangeEvent = (event) => {
  const $target = $(event.target);
  const $container = $target
    .closest('.legal-entity-type-group')
    .siblings('.legal-entity-form-fields');

  const type = $target.val();

  if (!type) {
    $container.empty();
    return;
  }

  const query = $.param({
    'legal_entity[parent_entity_type]': $target.data('parent-entity-type'),
    'legal_entity[parent_entity_id]': $target.data('parent-entity-id'),
    'legal_entity[type]': type,
    fields_name: $target.data('fields-name'),
  });
  fetchFields(`/legal_entities/fields/new?${query}`, $container);
};

/**
 * Sets the visibility of a given container.
 * - when showing the container, all its children of type input are enabled.
 * - when hiding the container, all its children of type input are disabled.
 *
 * @param {*} $container element been affected.
 * @param {*} visible indicates whether it should show or hide the given
 *   container.
 */
const setFieldsVisibility = ($container, shouldShow) => {
  if (shouldShow) {
    $container.removeClass('hidden');
    $('input, select, textarea', $container).prop('disabled', false);
  } else {
    $container.addClass('hidden');
    $('input, select, textarea', $container).prop('disabled', true);
  }
};

const handleCollapseToggle = () => {
  const toggle = $('[data-toggle="collapse"]');
  toggle.toggleClass('active');
  if (toggle.hasClass('active')) {
    toggle.text('Show Less');
  } else {
    toggle.text('Learn More');
  }
};


const handleCreateNewEntity = (event) => {
  event.preventDefault();
  const associateFieldsId = event.target.getAttribute('data-associate-field-id');
  const $associateSelect = $(`#${associateFieldsId}`);

  $associateSelect.removeAttr('required');

  // Clear selection
  $associateSelect.val('').trigger('change');

  const $existingFields = $associateSelect.closest('.associated-entity-fields-existing');
  const $newFields = $existingFields.siblings('.associated-entity-fields-new');
  setFieldsVisibility($newFields, true);
  initFieldFeatures($('.validate-form'));
};

const handleSelectExistingEntity = (event) => {
  const associateFieldsId = event.target.dataset.select2Id;
  const $associateSelect = $(`#${associateFieldsId}`);

  $associateSelect.attr('required', 'true');

  const $existingFields = $associateSelect.closest('.associated-entity-fields-existing');
  const $newFields = $existingFields.siblings('.associated-entity-fields-new');
  setFieldsVisibility($newFields, false);
  initFieldFeatures($('.validate-form'));
};

const handleExistingEntitySelect = (e) => {
  const newEntityLinkStyle =
    `padding: 0.5rem 1rem;
     height: 1.25rem;
     display: inline-table;
    `;

  const newEntityLink =
    `<a href="#" class="float-right select2-link"
        data-associate-field-id=${e.target.id}
        style="${newEntityLinkStyle}">+ Create new
     </a>
    `;

  const a = $(`#${e.target.id}`).data('select2');
  if (!$('.select2-link').length) {
    a.$results.parents('.select2-results')
      .append(newEntityLink)
      .on('click', (event) => {
        a.trigger('close');
        handleCreateNewEntity(event);
      });
  }
};

const init = () => {
  const $errorDiv = $('.alert-danger');
  $('.legal-entity-form')
    .on('change', '.legal-entity-type', handleLegalEntityTypeChangeEvent)
    .on('click', '.wallet-disclosure-collapse', handleCollapseToggle)
    .on('select2:open', '.existing-entity-select', handleExistingEntitySelect)
    .on('select2:select', '.existing-entity-select', handleSelectExistingEntity);

  // Enable footer buttons if there's form validation errors
  if ($errorDiv.length > 0) {
    $('.ftf-monolith-footer-btn').removeClass('d-none');
  }
};

export default {
  type: 'selector',
  selector: '.legal-entity-form',
  init,
};
