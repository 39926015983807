/* eslint-disable no-restricted-syntax */
// helpers
/**
 * Clears a given select field. It also appends a blank/prompt option to it.
 *
 * @param $field Field to be cleared.
 * @param disabled Indicates whether it should also be disabled or not.
 * @param placeholderOption placeholder option.
 * @return {jQuery} Given select field.
 */
export const clearSelectField = (
  $field,
  disabled = false,
  placeholderOption = null,
) => {
  let defaultOption = '<option value="">Select one...</option>';

  if (placeholderOption != null) {
    defaultOption = placeholderOption;
  }

  return $field
    .removeAttr('value')
    .empty()
    .append(defaultOption)
    .prop('disabled', disabled);
};

export const normalizeId = id => ((id[0] === '#') ? id : `#${id}`);

export const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
});

export const isFieldFilled = (id) => {
  const value = $(normalizeId(id)).val();

  return value != null && value !== '';
};

export const toggleAddBtn = (formId, fieldsTocheck, btnId = '') => {
  const selector = `#${btnId || (`${formId} .add-btn`)}`;
  for (let i = 0; i < fieldsTocheck.length; i++) {
    if (!isFieldFilled(fieldsTocheck[i])) {
      $(selector).prop('disabled', true);
      return;
    }
  }

  $(selector).prop('disabled', false);
};
