import { toggleAddBtn } from '../util';
import ACCOUNT_FIELDS_ENUM from '../enums/add_account_fields_enum';

const setModalInformation = (e) => {
  const accountId = $(e.target).data('account-id');
  const legalEntityId = $(e.target).data('legal-entity-id');
  const investorId = $(e.target).data('investor-id');
  $('#account-id-input').val(accountId);
  $('#legal-entity-id-input').val(legalEntityId);
  $('#investor-id-input').val(investorId);
};

const handleCloseModal = () => {
  window.parent.postMessage({ message: 'closeModal' }, '*');
};

const toggleConfirmButton = () => {
  const amount1 = $('#microdeposit-amount-1').val();
  const amount2 = $('#microdeposit-amount-2').val();

  if (amount1 !== '$' && amount2 !== '$') {
    $('#confirm-verify-account-btn').prop('disabled', false);
  }
};

const handleSubmitLoading = () => {
  $('#form-spinner-overlay').show();
  $('#confirm-verify-account-btn').toggleClass('disabled');
};

const init = () => {
  $('#confirm-account-delete').on('show.bs.modal', setModalInformation);
  $('.microdeposit-amount').on('keyup change', toggleConfirmButton);
  $('.verify-account-btn').on('click', setModalInformation);
  $('#dashboard-return-btn').on('click', handleCloseModal);
  $('#add-account-form').on('keyup keypress change', () => {
    toggleAddBtn('add-account-form', Object.values(ACCOUNT_FIELDS_ENUM));
  });
  $('#confirm-verify-account-btn').on('click', handleSubmitLoading);
};

// todo: break down into multiple modules (e.g. account verification, etc.)
export default {
  type: 'selector',
  selector: '#verify-account-modal',
  init,
};
