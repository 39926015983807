import accounts from './ach_account_verification';
import accountForm from './account_form';
import accountAggregation from './account_aggregation';
import disbursements from './new_disbursement';
import forms from './forms';
import FtfLegalEntityForm from './ftf_legal_entity_form';
import FtfLegalEntitySuccess from './ftf_legal_entity_success';
import modals from './modals';
import issuers from './issuers';
import legalEntityForm from './legal_entity_form';
import newDistribution from './new_distribution';
import newInvestment from './new_investment';
import newInvestorPayment from './new_investor_payment';
import newOfferingHolding from './new_offering_holding';
import newPayment from './new_payment'
import sidebar from './sidebar';
import select2 from './select2';
import investorEntityWallet from './investor_entity_wallet';
import newOffering from './new_offering';
import filter from './filter';
import search from './search';
import paymentIdSearch from './payment_id_search';
import users from './users';
import session_reporting from './session_reporting';
import popover from '../../common/modules/popover';
import legalEntitiesAccountsShow from './ftf_monolith/legal_entities/accounts/show';
import accountsVerificationsNew from './ftf_monolith/accounts/verifications/new';

export default [
  accountAggregation,
  accountForm,
  accounts,
  accountsVerificationsNew,
  disbursements,
  filter,
  forms,
  FtfLegalEntityForm,
  FtfLegalEntitySuccess,
  investorEntityWallet,
  issuers,
  legalEntitiesAccountsShow,
  legalEntityForm,
  modals,
  newDistribution,
  newInvestment,
  newInvestorPayment,
  newOffering,
  newOfferingHolding,
  newPayment,
  paymentIdSearch,
  popover,
  search,
  select2,
  session_reporting,
  sidebar,
  users,
];
