const init = () => {
  const legalEntityId = $('#legal_entity_id').val();

  window.parent.postMessage({
    message: 'Radix:newEntityCreated',
    legalEntityId,
  }, '*');
};

export default {
  type: 'selector',
  selector: '.ftf_monolith_entity_success',
  init,
};
