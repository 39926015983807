import { clearSelectField, currencyFormatter } from '../util';
import { setUpAutocomplete } from '../autocomplete';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const option = ({ id, nickname, issuerName }) => `
  <option value='${id}'>${issuerName} - ${nickname}</option>
`;

const onSelectTargetAccount = (event) => {
  const targetAccountId = $(event.target).val();
  $cache('#payment_disbursement_payment_target_account_id')
    .val(targetAccountId);
};


const onSelectOffering = (event, { item: offering }) => {
  const options = offering.issuer.active_source_accounts
    .filter(account => account.type === 'AchAccount' || account.type === 'WireAccount')
    .map(account => ({ ...account, issuerName: offering.issuer.name }))
    .map(option);

  clearSelectField($cache('#payment_disbursement_payment_target_account_id'), false)
    .append(options);
  $cache('#payment_disbursement_payment_amount')
    .prop('disabled', false);
  $cache('#payment_disbursement_payment_payable_id')
    .val(offering.id);
  $cache('#payment_disbursement_payment_source_account')
    .val(offering.name);
  $cache('#payment_disbursement_payment_source_account_id')
    .val(offering.deposit_account.id);
  $cache('#source_account_balance')
    .val(currencyFormatter.format(offering.deposit_account.balance));
  $cache('#payment_disbursement_payment_amount')
    .attr('data-max-value', parseFloat(offering.offering_detail.disbursable_balance));
  $cache('#source_account_disbursable_balance')
    .val(`${currencyFormatter.format(offering.offering_detail.disbursable_balance)}`);

  return false;
};


const cleanFields = () => {
  $cache('#payment_disbursement_payment_source_account_id')
    .val('');
  $cache('#payment_disbursement_payment_payable_id')
    .val('');
  clearSelectField($cache('#payment_disbursement_payment_target_account_id'), true);
  $cache('#payment_disbursement_payment_amount').prop('disabled', true);
};

const init = () => {
  $('#payment_disbursement_payment_target_account_id')
    .on('change', onSelectTargetAccount);
  setUpAutocomplete({
    id: '#payment_disbursement_payment_source_account',
    onKeyup: cleanFields,
    onItemSelected: onSelectOffering,
    url: '/autocomplete/offerings',
  });
};

export default {
  type: 'selector',
  selector: '#new_payment_disbursement_payment',
  init,
};
