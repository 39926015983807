import { clearSelectField } from '../util';
// variables
let $legalEntityIdElem;
let $paymentMethodIdElem;
let $offeringAccountIdElem;
let $amountElement;
let $investorIdElem;

let legalEntities;
let accounts;

/**
 * Handles changes to the `#investment_legal_entity_id` field.
 *
 * If its value is empty, it clears and disables the
 * `#investment_investment_payment_attributes_source_account_id`
 * field.
 * If its value is not empty, it enables the
 * `#investment_investment_payment_attributes_source_account_id` field and
 * appends the accounts of the selected legal entity as options.
 */
const onSelectLegalEntity = () => {
  const legalEntityId = $legalEntityIdElem.val();
  const investorId = $investorIdElem.val();

  if (!legalEntityId) {
    clearSelectField($paymentMethodIdElem, true);
    return;
  }

  // Fetch all the accounts that will be used as a source for investments.
  fetch(`/investors/${investorId}/legal_entities/${legalEntityId}/accounts.json`)
    .then(response => response.json())
    .then((data) => {
      accounts = data.accounts
        .filter(account => account.status === 'credit_and_debit')
        .map(account => `<option value="${account.id}">${account.nickname}</option>`);
      clearSelectField($paymentMethodIdElem, false).append(accounts);
    });
};

/**
 * Handles changes to `#investment_investor` field.
 *
 * It populates `#investment_investor_id` field.
 * It also enables the `#investment_legal_entity_id` field and appends
 * the legal entities of the selected investor as options.
 */
const onSelectInvestor = (event) => {
  const investorId = event.target.value;

  if (!investorId) {
    clearSelectField($paymentMethodIdElem, true);
  }

  fetch(`/investors/${investorId}/legal_entities.json`)
    .then(response => response.json())
    .then((data) => {
      legalEntities = data.map(legalEntity =>
        `<option value="${legalEntity.id}">${legalEntity.type} - ${legalEntity.name}</option>`);
      clearSelectField($paymentMethodIdElem, false);
      clearSelectField($legalEntityIdElem, false)
        .append(legalEntities)
        .on('change', onSelectLegalEntity);
    });
};

/**
 * Handles changes to `#investment_offering` field.
 *
 * It populates '#investment_investment_payment_attributes_target_account_id' field.
 */
const onSelectOffering = (event) => {
  const depositAccountId = event.params.data.target_account_id;

  if (!depositAccountId) {
    $offeringAccountIdElem.removeAttr('value');
  }

  $offeringAccountIdElem.val(depositAccountId);
};

const setMaxValueForAmountField = (e) => {
  const optionElement = $(`#investment_investment_payment_attributes_source_account_id option[value="${e.target.value}"]`);
  $amountElement = $('#investment_investment_payment_attributes_amount');
  if (optionElement.data('wallet')) {
    $amountElement.attr('data-max-value', parseFloat(optionElement.data('balance')));
  } else {
    $amountElement.attr('data-max-value', null);
  }

  $amountElement.trigger('blur');
};

const setInvestmentAmountToModal = (e) => {
  const $target = $(e.target);
  $amountElement = $('#investment_investment_payment_attributes_amount');
  const modalSelector = $target.data('target');
  const $amountOutput = $(`${modalSelector} #amount-output`);
  const dataValue = $amountElement.val();

  $amountOutput.text(dataValue || 0.00);
};

const init = () => {
  // caching elements
  $legalEntityIdElem = $('#investment_legal_entity_id');
  $investorIdElem = $('#investment_investor_id');
  $paymentMethodIdElem = $('#investment_investment_payment_attributes_source_account_id');
  $offeringAccountIdElem = $('#investment_investment_payment_attributes_target_account_id');
  $amountElement = $('#investment_investment_payment_attributes_amount');
  const $submitButton = $('#investment_btn_submit');

  $('.investors-select').select2({
    minimumInputLength: 1,
    ajax: {
      url: '/investors',
      dataType: 'json',
      type: 'GET',
      delay: 150,
      data: params => ({ term: params.term }),
      processResults: (data) => {
        const res = data.map(item => ({ id: item.id, text: `${item.first_name} ${item.last_name}` }));
        return {
          results: res,
        };
      },
    },
  });

  $('.offerings-select').select2({
    minimumInputLength: 1,
    ajax: {
      url: '/offerings',
      dataType: 'json',
      type: 'GET',
      delay: 150,
      data: params => ({ term: params.term, not_archived: true }),
      processResults: (data) => {
        const res = data.map(item => ({ id: item.id, text: `${item.name}`, target_account_id: item.deposit_account_id }));
        return {
          results: res,
        };
      },
    },
  });

  $paymentMethodIdElem.on('change', setMaxValueForAmountField);
  $submitButton.on('click', setInvestmentAmountToModal);

  $amountElement.on('keyup', (e) => {
    $submitButton.data('amount', e.target.value);
  });

  $('.investors-select').on('select2:select', onSelectInvestor);
  $('.offerings-select').on('select2:select', onSelectOffering);
};

export default {
  type: 'selector',
  selector: '#new_investment',
  init,
};
