import { initFieldFeatures } from '../utils/fields';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const init = () => {
  $cache('.filter-submit').on('click', () => {
    $('#filter-by').trigger('submit');
  });

  $('#start_date')
    .on('change', (e) => {
      $cache('#end_date').attr('data-min', e.target.value);
    })
    .trigger('change');
  // initializes field features.
  initFieldFeatures($cache('#filter-by'));
};

export default {
  type: 'selector',
  selector: '#filter-by',
  init,
};
