const setMessageError = (event) => {
  const messageError = $('#message_error');
  const target = $(event.target);
  if (messageError.length) {
    messageError
      .text(`The ${target.attr('class').split(' ').pop()} option is disabled until an account is added`);
    messageError.removeClass('invisible');
    target.attr('disabled', true);
  }
};

const init = () => {
  $('.deposit, .withdraw').on('click', setMessageError);
};

export default {
  type: 'selector',
  selector: '.verify-message-error',
  init,
};
