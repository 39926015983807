// Toggles left sidebar on/off
const toggleSidebar = () => {
  $('.wrapper').toggleClass('toggled');
  $('.sidebar').toggleClass('d-none');
};

const init = () => {
  $('#menu-toggle').on('click', toggleSidebar);
};

export default {
  type: 'global',
  init,
};
