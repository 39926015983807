const init = () => {
  // Initializes select2 plugin and set global defaults properties.
  $.fn.select2.defaults.set('width', 'style');
  $.fn.select2.defaults.set('theme', 'bootstrap4');
};

export default {
  type: 'global',
  init,
};
