import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const FLASH_MESSAGE_HEIGHT_COMPENSATION = 120;

const FORM_SELECTOR = '.ftf-monolith-legal-entity';
const FOOTER_SELECTOR = '.ftf-monolith-footer';

const observeFormSizeChanges = () => {
  const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  const container = document.querySelector(FORM_SELECTOR);

  const observer = new ResizeObserver(([form]) => {
    if (!form) return;

    const { width, height } = form.contentRect;
    const { height: footerHeight } = document
      .querySelector(FOOTER_SELECTOR)
      .getBoundingClientRect();

    const message = {
      message: 'Radix:EntityFormSizeChanged',
      size: {
        width,
        height: height + footerHeight + FLASH_MESSAGE_HEIGHT_COMPENSATION,
      },
    };

    window.parent.postMessage(message, '*');
  });

  observer.observe(container);
};

const handleSubmitLoading = () => {
  $('#form-spinner-overlay').show();
  $('.btn-back').toggleClass('disabled');

  window.parent.postMessage({
    message: 'Radix:newEntityCreating',
  }, '*');
};

const init = () => {
  const $submitBtn = $('.btn-submit');
  $submitBtn.on('click', handleSubmitLoading);

  const $alertDanger = $('.alert-danger');
  if ($alertDanger.length > 0){
    window.parent.postMessage({
      message: 'Radix:errorOnNewEntityCreating',
    }, '*');
  }

  observeFormSizeChanges();
};

export default {
  type: 'selector',
  selector: '.ftf-monolith-legal-entity',
  init,
};

