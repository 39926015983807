const init = () => {
  $('.start-investing-span').on('click', handleAccountCreationClick);
};

export default {
  type: 'selector',
  selector: '.investor-entity-verification-new',
  init,
};

const handleAccountCreationClick = () => {
  window.parent.postMessage({message: 'radix/account/verify'}, '*');
}
