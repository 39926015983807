import JSEncrypt from 'jsencrypt';

import { clearSelectField } from '../util';

let encrypt;

let stage = 'credentials';

let $form;

let $credentials;
let $bankSelect;
let $usernameField;
let $passwordField;

let $questionnaire;
let $accessTokenField;
let $questionField;

let $accounts;

let banksData;
let $bankLogo;

let $submitButton;
let $newAccounFormtSubmitButton;

let legalEntityId;
let fieldsName;
let accounts;

const handleFailure = (error) => {
  console.log('Account Aggregation | request failed', error);
  $('.loading').addClass('hidden');
  $('.linking-account-message').addClass('hidden');
};

const postOptions = payload => ({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
});

const loadBanks = () => {
  fetch('/banking_service/banks')
    .then(response => response.json())
    .then((response) => {
      if (response.code !== 200) {
        return;
      }
      const options = response
        .data
        .map(bank => `<option value="${bank.code}">${bank.name}</option>`);

      // store banks response data
      banksData = response.data;

      clearSelectField($bankSelect, false);
      $bankSelect
        .append(options);
    });
};

const updateLogo = (e) => {
  const bankId = e.params.data.id;
  const bankName = e.params.data.text;
  const emptyLogoOption = ['Select one...', 'Fake'];

  if (emptyLogoOption.includes(bankName)) {
    $bankLogo.hide();
  } else {
    const selectedBank = banksData.find(bank => bank.code === bankId);
    $bankLogo.attr('src', selectedBank.logo);
    $bankLogo.attr('alt', selectedBank.name);
    $bankLogo.show();
  }
};

const prepareQuestionnarie = ({ access_token: accessToken, question }) => {
  stage = 'questionnarie';

  $credentials.addClass('hidden');
  $questionnaire.removeClass('hidden');

  $accessTokenField.val(accessToken);

  $('#questionnarie_question').text(question);
  $questionField.val('');

  $submitButton.prop('disabled', false);
};

const prepareAccountChooser = (_accounts) => {
  accounts = _accounts;

  stage = 'accounts';

  $credentials.addClass('hidden');
  $questionnaire.addClass('hidden');
  $accounts.removeClass('hidden');

  if (accounts.length === 0) {
    $accounts
      .empty()
      .append('All capable ACH accounts have been linked already.');
  } else {
    const options = accounts
      .map((account) => `<div class="form-check">
        <input class="form-check-input account-option" type="radio" name="${fieldsName}" value="${account.id}" id="account_${account.id}"></input>
        <label class="form-check-label" for="account_${account.id}">${account.nickname}</label>
      </div>`);
    $accounts
      .empty()
      .append(options);
  }
};

const handleResponse = (data) => {
  if (!!data.access_token && !!data.question) {
    prepareQuestionnarie(data);
  } else {
    prepareAccountChooser(data);
  }
};

const getCredentialsSubmissionData = () => {
  const bank = $bankSelect.val();
  const username = $usernameField.val();
  const password = $passwordField.val();
  if (!username || !password) {
    return null;
  }
  return {
    path: '/banking_service/banks/sessions',
    options: postOptions({
      bank,
      username: encrypt.encrypt(username),
      password: encrypt.encrypt(password),
      legal_entity_id: legalEntityId,
    }),
    $field: $passwordField,
  };
};

const getQuestionnarieSubmissionData = () => {
  const accessToken = $accessTokenField.val();
  const answer = $questionField.val();
  if (!accessToken || !answer) {
    return null;
  }
  return {
    path: '/banking_service/banks/sessions/answer_submissions',
    options: postOptions({
      answer: encrypt.encrypt(answer),
      access_token: accessToken,
      legal_entity_id: legalEntityId,
    }),
    $field: $questionField,
  };
};

const accountLinked = (accountId) => {
  const legalEntityIdFromPath = window.location.pathname.split('/')[3];
  const path = `ftf_monolith/legal_entities/${legalEntityIdFromPath}/accounts/${accountId}`;
  window.location.pathname = path;
};

const getStageSubmissionData = () => {
  switch (stage) {
    case 'credentials':
      return getCredentialsSubmissionData();
    case 'questionnarie':
      return getQuestionnarieSubmissionData();
    default:
      return null;
  }
};
const handleSubmit = (event) => {
  if (stage === 'accounts') {
    $(`input[name='${fieldsName}']`).each((index, element) => {
      if ($(element).prop('checked')) {
        const selectedAccountId = $(element).val();
        if (window.location.pathname.split('/')[4] === 'bank_logins') {
          accountLinked(selectedAccountId);
        }
        return;
      }
      const accountId = $(element).val();
      fetch(`/accounts/${accountId}`, { method: 'DELETE' })
        .then((response) => response.json())
        .then((response) => console.info(response));
    });
    return;
  }

  event.preventDefault();

  const submissionData = getStageSubmissionData();
  if (!submissionData) {
    return;
  }
  const { path, options, $field } = submissionData;

  $submitButton.prop('disabled', true);
  $('.loading').removeClass('hidden');

  if (stage === 'credentials') {
    $('.linking-account-message').removeClass('hidden');
  }

  fetch(path, options)
    .then(response => response.json())
    .then((response) => {
      $('.loading').addClass('hidden');
      $('.linking-account-message').addClass('hidden');

      if (response.status >= 200 && response.status < 400) {
        handleResponse(response.data);
      } else {
        $field
          .removeClass('is-valid')
          .addClass('is-invalid');
        $field.parent()
          .append(`<div class="invalid-feedback">${response.message}</div>`);
      }
    })
    .catch(handleFailure);
};

const init = () => {
  // setting up JS encrypt
  encrypt = new JSEncrypt();
  encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxy7xFpupeVxgUiaPneI1WAioSIeL6+/NAIIjbvDOvdTCNUeI//ob4bfdGLYlXpXOor/5POqfheZnHzkTu6BhDQqGZBc2BLaARlqx0s+twIadPwzqOJETzmp7r5U5ZioluOGDw4CF+JKRL6sBOaYr5wJ3BemZOXqQE7SAqIsi6Sej2ijGzVFq4tR3gogAdMKjGhzDwthqzZViZN1Zhzb8jsX/aCY+OWq9IUp4iX41fYmpfI9klVKnneAuVAIOPhI5zMaZ7JiQ+88ZQngTi6IhieyGIjvfG7FTsLNoYEqu1OeKw3SRt+HSs+LpnO3P9wwWODZtr07H7oxNJDAIoXITPwIDAQAB
  -----END PUBLIC KEY-----`);

  // caching elements
  const $accountAggregation = $('.account-aggregation');

  $form = $accountAggregation.closest('form');

  $credentials = $('.credentials', $accountAggregation);

  $bankLogo = $('.bank-logo');
  $bankSelect = $('.bank-select', $credentials);
  $bankSelect.on('select2:select', updateLogo);

  $usernameField = $('input[name="username"]', $credentials);
  $passwordField = $('input[name="password"]', $credentials);

  $questionnaire = $('.questionnaire', $accountAggregation);
  $accessTokenField = $('input[name="access_token"]', $questionnaire);
  $questionField = $('input[name="question"]', $questionnaire);

  $accounts = $('.accounts', $accountAggregation);

  $submitButton = $('.btn-submit');
  $newAccounFormtSubmitButton = $('.new-acc-form-submit');

  // initing values
  legalEntityId = $accountAggregation.data('legal-entity-id');
  fieldsName = $accountAggregation.data('fields-name');

  // loading banks
  loadBanks();

  // hooking up to on submit events.
  $newAccounFormtSubmitButton.on('click', handleSubmit);
  $form.submit(handleSubmit);
};

export default {
  type: 'selector',
  selector: '.account-aggregation',
  init,
};
