import { clearSelectField } from '../util';
import { setUpAutocomplete } from '../autocomplete';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const option = ({ id, nickname, issuerName }) => `
  <option value='${id}'>${issuerName} - ${nickname}</option>
`;

const onSelectSourceAccount = (event) => {
  const sourceAccountId = $(event.target).val();
  $cache('#distribution_distribution_payment_attributes_source_account_id')
    .val(sourceAccountId);
};

const onSelectOffering = (event, { item: offering }) => {
  const options = offering.issuer.ach_accounts
    .map(account => ({ ...account, issuerName: offering.issuer.name }))
    .map(option);

  $cache('#distribution_distribution_payment_attributes_target_account_id')
    .val(offering.issuer.distributions_account.id);
  clearSelectField($cache('#distribution_distribution_payment_attributes_source_account_id'), false)
    .append(options);
  $cache('#distribution_offering_id').val(offering.id);
  $cache('#distribution_offering').val(offering.name);

  return false;
};

const cleanFields = () => {
  $cache('#distribution_offering_id').val('');
  $cache('#distribution_distribution_payment_attributes_target_account_id').val('');
  clearSelectField($cache('#distribution_distribution_payment_attributes_source_account_id'), true);
};

const init = () => {
  $('#distribution_distribution_payment_attributes_source_account_id')
    .on('change', onSelectSourceAccount);
  setUpAutocomplete({
    id: '#distribution_offering',
    onKeyup: cleanFields,
    onItemSelected: onSelectOffering,
    url: '/offerings?not_archived=true',
  });
};

export default {
  type: 'selector',
  selector: '.distribution_form',
  init,
};
