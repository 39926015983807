import { initFieldFeatures } from '../utils/fields';

/**
 * Fetches all the fields of a given route.
 *
 * @param route GET request path and query object
 * @param $container jquery element where fields are going to be shown.
 */
export const fetchFields = (route, $container) => {
  const $loadingOverlay = $('#form-spinner-overlay.loading');
  const $footerButtons = $('.ftf-monolith-footer-btn');

  // hide buttons and show loading while fetching.
  $loadingOverlay.show();
  $footerButtons.addClass('d-none');

  fetch(route)
    .then(response => response.text())
    .then((response) => {
      $container
        .empty()
        .html(response);
      initFieldFeatures($container);

      // display footer buttons and hide loading when finished fetching.
      $footerButtons.removeClass('d-none');
      $loadingOverlay.hide();
    })
    .catch(error => console.error(error)); // eslint-disable-line no-console
};
