import { normalizeId } from './util';

/**
 * Builds a renderer function that renders a jQuery UI Autocomplete item from
 * an option-like object with value and label properties.
 *
 * @param mapToItem Maps item to an option-like object with value and label properties.
 * @return {function(*=, *=): jQuery}
 */
export const itemRenderer = mapToItem => (ul, item) => {
  const { value, label } = mapToItem(item);
  return $('<li>')
    .attr('data-value', value)
    .append(label)
    .appendTo(ul);
};

export const setUpAutocomplete = ({
  id, onKeyup, onItemSelected, url, customMapper,
}) => {
  const autocomplete = $(normalizeId(id))
    .on('keyup', onKeyup)
    .autocomplete({ source: url, select: onItemSelected })
    .autocomplete('instance');

  let mapper = customMapper;
  if (customMapper == null) {
    mapper = item => ({
      value: item.id, label: item.name,
    });
  }

  autocomplete._renderItem = itemRenderer(mapper);
};
