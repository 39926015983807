const cache = new Map();

const elementCache = {
  get(selector) {
    if (cache.get(selector)) {
      return cache.get(selector);
    }

    cache.set(selector, $(selector));

    return cache.get(selector);
  },
  refresh() {
    cache.clear();
  },
};

export default elementCache;
