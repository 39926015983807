import { setUpAutocomplete } from '../autocomplete';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const onChangelegalEntity = () => {
  $cache('#holding_legal_entity_id').val('');
};

const onSelectlegalEntity = (event, { item: legalEntity }) => {
  $cache('#holding_legal_entity_id').val(legalEntity.id);
  $cache('#holding_legal_entity_name').val(legalEntity.name);

  return false;
};

const init = () => {
  setUpAutocomplete({
    id: '#holding_legal_entity_name',
    onKeyup: onChangelegalEntity,
    onItemSelected: onSelectlegalEntity,
    url: '/autocomplete/legal_entities',
    customMapper: item => ({
      value: item.id, label: `${item.name} - ${item.id}`,
    }),
  });
};

export default {
  type: 'selector',
  selector: '.holding-form',
  init,
};
