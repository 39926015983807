import { toggleSubmitButton } from './validations';
/**
 * Handles `keyup` events triggered by `.address-location` fields.
 *
 * It binds each field (event's target) to Google's Place Autocomplete API so
 * that each time a new place is selected, all other address-like fields are
 * automatically filled.
 */

let autocomplete;
let address1Field;
let fullPrefix;

export const initializeAddressLocation = () =>{
  address1Field = document.querySelector(".address-line1");

  if(address1Field){
    autocomplete = new google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });

    fullPrefix = address1Field.id
    autocomplete.addListener("place_changed", function() { fillInAddress(fullPrefix); } )
  }
}

const fillInAddress = (fullPrefix) => {
  const place = autocomplete.getPlace();

  if (!place) {
    return;
  }

  let prefix = fullPrefix.replace('address_line1', '');
  const address = `${prefix}address_line1`;
  const city = `${prefix}city`;
  const state = `${prefix}state`;
  const zipCode = `${prefix}zip_code`;

  const fields = place.address_components.reduce((field, component) => {
    if (component.types.indexOf('street_number') >= 0) {
      return { ...field, [address]: { ...field[address], street_number: component.long_name } };
    }

    if (component.types.indexOf('route') >= 0) {
      return { ...field, [address]: { ...field[address], route: component.long_name } };
    } else if (component.types.indexOf('locality') >= 0) {
      return { ...field, [`${prefix}city`]: { value: component.long_name } };
    } else if (component.types.indexOf('administrative_area_level_1') >= 0) {
      return { ...field, [`${prefix}state`]: { value: component.short_name } };
    } else if (component.types.indexOf('postal_code') >= 0) {
      return { ...field, [`${prefix}zip_code`]: { value: component.long_name } };
    }
      return field;
    }, {
      [address]: {}, [city]: {}, [state]: {}, [zipCode]: {},
    });

    Object.keys(fields)
    .forEach((field) => {
      let value;

      if (field === address) {
        value = `${fields[field].street_number || ''} ${fields[field].route || ''}`.trim();
      } else {
        value = fields[field].value || '';
      }
      $(`#${field}`).val(value).trigger('change');

      // perform validation
      toggleSubmitButton();
    });
}
