import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const setFieldsVisibility = (selector, visible) => {
  const $fields = $cache(selector);
  if (visible) {
    $fields.removeClass('hidden');
  } else {
    $fields.addClass('hidden');
  }
  $fields
    .children('.form-control')
    .attr('disabled', !visible);
};

const toggleFields = (e) => {
  const { value } = e.target;

  switch (value) {
    case 'fund':
      setFieldsVisibility('.fund-type-fields', true);
      setFieldsVisibility('.BPDN-type-fields', false);
      break;
    case 'borrower_payment_dependent_note':
      setFieldsVisibility('.fund-type-fields', false);
      setFieldsVisibility('.BPDN-type-fields', true);
      break;
    default:
      setFieldsVisibility('.fund-type-fields', false);
      setFieldsVisibility('.BPDN-type-fields', false);
      break;
  }
};

const toggleSubtype = (e) => {
  const { value } = e.target;
  $cache('#offering_offering_type').attr('disabled', false);
  switch (value) {
    case 'deal':
      setFieldsVisibility('.series-note-fields', false);
      break;
    case 'series_note_offering':
      setFieldsVisibility('.series-note-fields', true);
      $cache('#offering_offering_type').val('fund').trigger('change');
      $cache('#offering_offering_type').attr('disabled', true);
      break;
    default:
      setFieldsVisibility('.series-note-fields', false);
      break;
  }
};

const calculateInvestorYield = () => {
  const $interestRateElement = $cache('#offering_interest_rate');
  const spreadRateElement = $cache('#offering_spread_rate');
  const $investorYieldElement = $cache('#investor_yield');
  if (!$interestRateElement.val() || !spreadRateElement.val()) {
    $investorYieldElement.text('0.00');
  } else {
    $investorYieldElement.text(($interestRateElement.val() - spreadRateElement.val()).toFixed(4));
  }
};

const referenceCancelModal = () => {
  $('.btn-cancel')
    .attr('data-toggle', 'modal')
    .attr('data-target', '#confirmation_cancel_modal')
    .attr('href', '');
};

const setMaxToMinimunInvestmentAmount = () => {
  $('#offering_minimum_investment_amount').attr(
    'data-max-value',
    $cache('#offering_target_amount').val().substr(1).trim().replace(/,/g, '')
  );
};

const init = () => {
  $('.issuers-select').select2({
    minimumInputLength: 1,
    ajax: {
      url: '/issuers.json',
      dataType: 'json',
      type: 'GET',
      delay: 150,
      data: params => ({ term: params.term }),
      processResults: (data) => {
        const res = data.map(item => ({ id: item.id, text: `${item.name}` }));
        return {
          results: res,
        };
      },
    },
  });

  $cache('#offering-form')
    .on('change', '#offering_offering_type', toggleFields)
    .on('change', '#offering_ftf_subject_type', toggleSubtype)
    .on('change', '#offering_target_amount', setMaxToMinimunInvestmentAmount)
    .on('keyup', '#offering_interest_rate', calculateInvestorYield)
    .on('keyup', '#offering_spread_rate', calculateInvestorYield)
    .on('change', 'input, select, textarea', referenceCancelModal);

  $cache('#offering_offering_type').trigger('change');
  $cache('#offering_ftf_subject_type').trigger('change');
  $cache('.btn-submit').attr('disabled', true);
};

export default {
  type: 'selector',
  selector: '#offering-form',
  init,
};
