import { clearSelectField } from '../util';
import { mapAccountOption } from '../select_filters/legal_entities_filter';

let $legalEntityElem;
let $paymentMethodElem;

const fetchHolding = (offeringId, holdingId, $element) => {
  fetch(`/offerings/${offeringId}/holdings/${holdingId}`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(response => response.json())
    .then((data) => {
      const { legal_entity: legalEntity } = data;

      const options = legalEntity.accounts.map(mapAccountOption);

      clearSelectField($element, false)
        .append(options);
    });
};

const fillPaymentMethodDetails = (event) => {
  const offeringId = $('#payment_investor_payment_offering_id').val();
  const holdingId = event.target.value;
  if (!holdingId) {
    clearSelectField($paymentMethodElem, true);
    return;
  }

  fetchHolding(offeringId, holdingId, $paymentMethodElem);
};

const init = () => {
  $legalEntityElem = $('#payment_investor_payment_holding_id');
  $paymentMethodElem = $('#payment_investor_payment_target_account_id');

  $legalEntityElem.on('change', fillPaymentMethodDetails);
};

export default {
  type: 'selector',
  selector: '.investor-payment-form',
  init,
};
