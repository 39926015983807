import { initFieldFeatures } from '../utils/fields';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const init = () => {
  $cache('.payment_searchbox__button').on('click', () => {
    $('#payment-id-search-form').trigger('submit');
  });

  // initializes field features.
  initFieldFeatures($cache('#payment-id-search-form'));
};

export default {
  type: 'selector',
  selector: '#payment-id-search-form',
  init,
};
