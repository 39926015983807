import { initFieldFeatures } from '../utils/fields';
import elementCache from '../element_cache';

const { get: $cache } = elementCache;

const init = () => {
  $cache('.searchbox__button').on('click', () => {
    $('#search-form').trigger('submit');
  });

  // initializes field features.
  initFieldFeatures($cache('#search-form'));
};

export default {
  type: 'selector',
  selector: '#search-form',
  init,
};
