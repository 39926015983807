import { toggleSubmitButton } from '../utils/validations';

let $passwordResetLink;
let $email;

const updateEmailParam = (event) => {
  const newAttr = event.target.href + $email.val();
  $passwordResetLink.attr('href', newAttr);
};

const init = () => {
  $email = $('.form-email');
  $passwordResetLink = $('.reset-password');
  $passwordResetLink.attr('href', '/users/password/new?email=');
  $passwordResetLink.on('click', updateEmailParam);
  toggleSubmitButton();
};

export default {
  type: 'selector',
  selector: '#new_user',
  init,
};
