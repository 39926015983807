import { fetchFields } from "../select_filters/form_filters";

/**
 * Handle changes to the field that determines the type of an account been
 * created by fetching and placing the fields required for the chosen type.
 */
const handleAccountTypeChangeEvent = (event) => {
  const $target = $(event.target);
  const $container = $target
    .closest(".account-type-group")
    .siblings(".account-form-fields");

  const type = $target.val();

  if (!type) {
    $container.empty();
    return;
  }

  fetchQuery($target, $container, type);
};

const fetchQuery = (target, container, type) => {
  const query = $.param({
    "account[payee_name]": target.data("payeeName"),
    "account[type]": type,
    fields_name: target.data("fields-name"),
    legal_entity_id: target.data("legal-entity-id"),
  });
  fetchFields(`/accounts/fields/new?${query}`, container);
};

const selectedAccountTypes = {
  checkRecipient: "CheckRecipient",
  achAccount: "AchAccount",
};

const init = () => {
  $(".account-type-group").hide();

  // support CheckRecipient and AchAccount only flows - I hate this (Taylor E Kems)
  const formDiv = document.querySelector("#account_selected_account_type");
  const accountTypeValue = formDiv && selectedAccountTypes[formDiv.value || null];
  if (formDiv && accountTypeValue) {
    const target = $(".account-type");
    const container = target
      .closest(".account-type-group")
      .siblings(".account-form-fields");
    fetchQuery(target, container, accountTypeValue);
  } else {
    $(".account-type-group").show();
  }

  $(".account-form").on(
    "change",
    ".account-type",
    handleAccountTypeChangeEvent
  );
};

export default {
  type: "selector",
  selector: ".account-form",
  init,
};
