import footerTemplate from '../templates/footer';

const fillDocumentTemplate = () => {
  const title = $('#document_template_title').val();
  const signatureServiceUrl = $('#document_template_signature_service_url').val();
  $('#btn_add_document_template').prop('disabled', !(title && signatureServiceUrl));
};

const handleDeleteTemplateModal = (e) => {
  e.preventDefault;
  const issuerId = $(e.target).data('issuer-id');
  const templateId = $(e.target).data('template-id');
  $('.modal-footer').html(footerTemplate('delete', `/${issuerId}`, '/document_templates', `/${templateId}`, 'Yes; Delete'));
  $('#modal_delete_template').modal('show');
};

const handleCancelAddProcessModal = (e) => {
  e.preventDefault;
  $('.modal-footer').html(footerTemplate('get', '', '', '', 'Yes; Cancel'));
  $('#modal_cancel_process').modal('show');
};


const init = () => {
  $('#document_template_title, #document_template_signature_service_url')
    .on('keyup', fillDocumentTemplate);
  $('.delete-document-template')
    .on('click', handleDeleteTemplateModal);
  $('.cancel-add-process')
    .on('click', handleCancelAddProcessModal);
  fillDocumentTemplate();
};

// todo: re-factor coming as part of RC 7 and 8.
export default {
  type: 'selector',
  selector: '#issuer-form',
  init,
};
