import ms from './modules';
import elementCache from './element_cache';

/**
 * Indicates whether a given module should be loaded or not.
 *
 * @returns `true` if any of the statements below is true, otherwise `false`.
 * - type of given module is `global`
 * - type of given module is `route` and route of given module is matches
 * current path.
 * - type of given module is `selector` and selector of the given module
 * exists.
 */
const shouldLoadModule = (m) => {
  if (m.type === 'global') {
    return true;
  }

  return (
    (m.type === 'route' && window.location.pathname === m.route) ||
    (m.type === 'selector' && $(`${m.selector}`).length > 0)
  );
};

/**
 * Loads all registered modules.
 *
 * There are three different types of modules, which are explained below.
 * - global modules, which are always loaded/initialized.
 * - route-based modules, which are only loaded/initialized if current path
 * matches its route.
 * - selector-based modules, which are only loaded/initialized if its selector
 * exists.
 */
const init = () => {
  $(document).on('turbolinks:load', () => {
    elementCache.refresh();

    ms
      // ensures modules are orderd (and loaded/initialized) as shown below:
      // 1. global modules
      // 2. route modules
      // 3. selector modules
      .sort((m1, m2) => {
        if (m1.type === 'global') {
          return -1;
        }
        if (m2.type === 'global') {
          return 1;
        }
        if (m1.type === 'route') {
          return -1;
        }
        if (m2.type === 'route') {
          return 1;
        }
        return 0;
      })
      .forEach((m) => {
        const shouldLoad = shouldLoadModule(m);
        if (!shouldLoad) {
          return;
        }
        m.init();
      });
  });
};

export default init;
