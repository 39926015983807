import { datadogRum } from '@datadog/browser-rum-slim';

// import {
  // currentEnv,
  // datadogRumAppId,
  // datadogRumClientToken,
  // isTest,
  // isDevelopment,
  // reportingReleaseVersion,
// } from '../../common/utils/env';

datadogRum.setUser({ id: window.__UID__ || 'NOT SET' });

const init = () => {
  datadogRum.init({
    applicationId: window.DD_RUM_APP_ID,
    clientToken: window.DD_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'radix',
    env: window.RAILS_ENV,
    version: window.REPORTING_RELEASE_VERSION,
    sampleRate: window.RAILS_ENV === 'development' || window.RAILS_ENV === 'test' ? 0 : 100,
    // Session replay not supported until we migrate off of browser-rum-slim
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event, context) => {
      // collect a RUM resource's response headers
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        event.context = {
          ...event.context,
          responseHeaders: context.response.headers,
        };
      }
    },
  });
  datadogRum.startSessionReplayRecording();
};

export default {
  type: 'global',
  init,
};
