const init = () => {
  $('.payable-type', '.payment-form').on('change', enableFields);
  $('.payable-id', '.payment-form').on('keyup', validateAssociation);
  $('.payable-type', '.payment-form').on('change', validateAssociation);
  $('.payment-type', '.payment-form').on('change', enablePurpose)
  $('.payment-form').on('change', enableSubmitButton)

  $('.accounts-select').select2({
    minimumInputLength: 1,
    ajax: {
      url: '/accounts',
      dataType: 'json',
      type: 'GET',
      delay: 150,
      data: params => ({ term: params.term }),
      processResults: (data) => {
        clearText('.account-error')
        const res = data.map(item => ({ id: item.id, text: `${item.nickname}- $${item.balance}` }));
        return {
          results: res,
        };
      },
      error: function (jqXHR, status, error) {
        populateText('.account-error', jqXHR.status + ": " + error + ". Please correct accounts")
        return { results: [] };
    }
    },
  });
}

const populateText = (klass, text) => {
  $(`${klass}`, '.payment-form').text(text)
}

const clearText = (klass) => {
  $(`${klass}`, '.payment-form').text('')
}

const enableFields = () => {
  $('input, select, textarea', '.payment-form')
    .not('.payment-submit-button')
    .prop('disabled', false);
};

const validateAssociation = () => {
  clearText('.payable-warning')
  clearText('.payable-error')
  clearText('.refund-payment-warning')

  const payable_id = $('.payable-id', '.payment-form').val()
  const payable_type = $('.payable-type', '.payment-form').val().toLowerCase()
  if(payable_type === ''){
    populateText('.no-association-warning', 'Please note that this new payment is not associated to a Class in Radix. If you wish to associate it to a Distribution, Investment, Offering, or Payment please select within the Association Class dropdown.')
    $('.payable-id', '.payment-form').val('')
    $('.payable-id', '.payment-form').prop('disabled', true);
  } else {
    clearText('.no-association-warning')
  }

  if(payable_type === '' || payable_id === '') return
  
  if(payable_type === 'investment'){
    CheckRefundPaymentPresence(payable_id)
  }

  fetchPayableData(payable_id, payable_type)
};

const CheckRefundPaymentPresence = (payable_id) =>{
  $.ajax({
    url: `/refund_payments/${payable_id}`.toLowerCase(),
    type: "get",    
    success: function(response) {
      if(response){
        populateText('.refund-payment-warning', `This investment is associated with refund payment(s) ${response.join(', ')}`)
      } else {
        clearText('.refund-payment-warning')
      }
    },
    error: function(xhr) {
      $('.refund-payment-warning', '.payment-form').text(xhr.statusText)
    }
  });
}

const fetchPayableData = (payable_id, payable_type) => {
  $.ajax({
    url: `/${payable_type}s/${payable_id}`.toLowerCase(),
    type: "get",
    dataType: 'json',
    success: function(response) {
      if(response){
        populateText('.payable-warning', `Please note the ${payable_type} is currently in a(n) ${response.status} status`)
      }
    },
    error: function(xhr) {
      populateText('.payable-error', xhr.statusText)
      $('.payment-submit-button').prop('disabled', true)
    }
  });
}

const enablePurpose = (event) => {
  if(event.target.value === 'Payment::DisbursementPayment'){
    $('.purpose-disabled').show();
  } else {
    $('.purpose-disabled').hide();
    $('.selected-purpose').val(null).trigger('change');
  }
};

const enableSubmitButton = () => {
  let requiredFields = [
    $('.amount', '.payment-form'),
    $('.source-account', '.payment-form'),
    $('.target-account', '.payment-form'),
  ]

  if($('.payment-type').val() === 'Payment::DisbursementPayment'){
    requiredFields.push($('.selected-purpose', '.payment-form'))
  } else {
    requiredFields.filter(el =>{
      el !== $('.selected-purpose', '.payment-form')
    })
  }
  
  if($('.payable-type').val() !== ''){
    requiredFields.push($('.payable-id', '.payment-form'))
    requiredFields.push($('.payable-type', '.payment-form'),)
  } else {
    requiredFields = requiredFields.filter(el => 
      el !== $('.payable-id', '.payment-form') && el !== $('.payable-type', '.payment-form')
    )
  }

  if(!$('.payable-error').text().includes('Not Found') && requiredFields.every(el => el.val() !== '')){
    $('.payment-submit-button').prop('disabled', false)
  } else {
    $('.payment-submit-button').prop('disabled', true)
  }
}

export default {
  type: 'selector',
  selector: '#new_payment',
  init,
};
