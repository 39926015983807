import { clearSelectField } from '../util';

const parseBalance = balance => `$${parseFloat(balance).toFixed(2)}`;
const isWallet = account => account.type === 'CustodyAccount';
export const mapAccountOption = account => (
  `
    <option value="${account.id}" data-wallet="${isWallet(account)}" data-balance="${account.balance}">
      ${account.nickname} ${isWallet(account) ? (` - ${parseBalance(account.balance)}`) : ''}
    </option>
  `
);

/**
 * Fetches all the accounts of a legal entity associated to a given id.
 *
 * @param legalEntityId legalEntityId
 * @param $element jquery element where accounts are going to be shown.
 * @return {Array} All the accounts of the legal entity associated to the given id.
 */
export const fetchAccounts = (legalEntityId, $element) => {
  fetch(`/legal_entities/${legalEntityId}/accounts`)
    .then(response => response.json())
    .then((data) => {
      const accounts = data.map(mapAccountOption);

      clearSelectField($element, false)
        .append(accounts);
    });
};
