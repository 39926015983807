import moment from 'moment';
import { initFieldFeatures } from '../utils/fields';
import { toggleSubmitButton } from '../utils/validations';
import { currencyFormatter } from '../util';

const configValidate = () => {
  $.validator.setDefaults({
    errorClass: 'invalid-feedback',
    highlight: (element) => {
      $(element)
        .removeClass('is-valid')
        .addClass('is-invalid');
    },
    unhighlight: (element) => {
      $(element)
        .removeClass('is-invalid')
        .addClass('is-valid');
    },
    errorElement: 'div',
    errorPlacement: (error, element) => error.insertAfter(element),
  });

  $.validator.messages.required = 'Please fill the information required.';

  $.validator.addMethod(
    'form-phone',
    value => /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value),
    'Please enter a valid phone number (Ex. (123) 456-7890)',
  );
  $.validator.addMethod(
    'form-money',
    value => /^\$? ?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?/.test(value),
    'Please enter a valid money Number (Ex. $ 10,000.00)',
  );
  $.validator.addMethod(
    'form-ein',
    value => /^[0-9]{9}$/.test(value),
    'Please enter a valid Employer Identification Number (Ex. 12-3456789)',
  );
  $.validator.addMethod(
    'form-zipcode',
    value => /^[0-9]{5}$/.test(value),
    'Please enter a valid Zip code (Ex. 123456)',
  );
  $.validator.addMethod(
    'form-ssn',
    value => /^[0-9]{9}$/.test(value),
    'Please enter a valid Social Security Number (Ex. 123-45-6789)',
  );
  $.validator.addMethod(
    'form-account-number',
    value => /^[0-9]{6,17}$/.test(value),
    'Please enter a valid Account Number (Ex. 123456789000)',
  );
  $.validator.addMethod(
    'form-routing-number',
    value => /^[0-9]{9}$/.test(value),
    'Please enter a valid Routing Number (Ex. 123456789)',
  );
  $.validator.addMethod(
    'form-email',
    value => /^([a-zA-Z0-9_.-])+([a-zA-Z0-9_.+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value),
    'Please enter a valid e-mail address (Ex. test@email.com)',
  );
  $.validator.addMethod(
    'form-money',
    (value, element) => {
      const currentValue = value.replace(/[^0-9.-]+/g, '');
      const maxValue = element.getAttribute('data-max-value');
      if (!maxValue) {
        return true;
      }
      return currentValue <= parseFloat(maxValue);
    },
    (_value, element) => (
      `Please enter a value less than or equal to ${
        currencyFormatter.format(element.getAttribute('data-max-value'))
      }`
    ),
  );
  $.validator.addMethod(
    'form-greater-than-zero',
    (value, element) => {
      const currentValue = value.replace(/[^0-9.-]+/g, '');
      const maxValue = element.getAttribute('data-max-value');

      if (parseInt(maxValue) > 0) {
        return currentValue > 0;
      }

      return true;
    },
    () => 'Please enter a value greater than zero',
  );
  $.validator.addMethod(
    'form-min-date',
    (value, element) => {
      if (!value || !element.getAttribute('data-min')) return true;
      const currentValue = new Date(value);
      const minValue = new Date(element.getAttribute('data-min'));

      return currentValue >= minValue;
    },
    (_value, element) => `Please enter a date after ${element.getAttribute('data-min')}`,
  );
  $.validator.addMethod(
    'form-round-to-max',
    (value, element) => {
      const currentValue = value.replace(/[^0-9.-]+/g, '');
      const maxValue = element.getAttribute('data-max-value');

      if (currentValue > parseFloat(maxValue)) {
        $(element).val(maxValue);
      }

      return true;
    },
    (_value, element) => `Please enter a value less than or equal to $${element.getAttribute('data-max-value')}`,
  );
  $.validator.addMethod(
    'minDate',
    (value, _, param) => {
      const parsed = new Date(value);
      const minDate = new Date(param);
      return parsed > minDate;
    },
    () => 'Please specify a date',
  );
  $.validator.addMethod(
    'maxDate',
    (value, _, param) => {
      const parsed = new Date(value);
      const maxDate = new Date(param);
      return parsed <= maxDate;
    },
    _value => `Must be on or before ${_value}`,
  );
  $.validator.addClassRules('form-birth-date', {
    minDate: '01/01/1899',
    maxDate: moment().subtract(18, 'years').calendar(),
  });
};

const disableBtn = () => {
  $('.btn-submit').attr('disabled', true);
};

const init = () => {
  const $form = $('.validate-form');

  // configures jQuery validate.
  configValidate();

  // hooks submit button toggler to the form.

  $form
    .on('keyup', 'input[type="text"], input[type="email"],' +
        'input[type="password"], textarea', toggleSubmitButton)
    .on('change', 'select, input[type="checkbox"],' +
        'input[type="radio"], .datepicker[required]', toggleSubmitButton)
    .on('submit', disableBtn);

  // initializes field features.
  initFieldFeatures($form);
};

export default {
  type: 'selector',
  selector: '.validate-form',
  init,
};
