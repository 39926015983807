import init from '../src/javascripts/init';

require('@rails/ujs').start();
require('turbolinks').start();

require('@rails/activestorage').start();

require('jquery');
require('jquery-validation/dist/jquery.validate');
require('jquery-ui/ui/widgets/autocomplete');
require('bootstrap/dist/js/bootstrap');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('inputmask/dist/inputmask/jquery.inputmask');
require('inputmask/dist/inputmask/inputmask.extensions');
require('inputmask/dist/inputmask/inputmask.numeric.extensions');
require('inputmask/dist/inputmask/inputmask.date.extensions');
require('select2/dist/js/select2.full');

init();
