import { toggleSubmitButton } from './validations';
import { initializeAddressLocation } from '../utils/addresses';

/**
 *  Initializes features that affect all fields for a given form.
 */
export const initFieldFeatures = ($form) => {
  $('.datepicker', $form).datepicker({
    format: 'mm/dd/yyyy',
    todayHighlight: true,
  });
  $(':input', $form).inputmask();
  $('select', $form).select2();

  $form.validate();
  toggleSubmitButton();

  $('.popover-dismiss').popover({
    container: 'body',
    trigger: 'focus',
  });

  initializeAddressLocation();
};
